import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { Link } from "gatsby"
import React, {
  Component,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { down, saveButton, chipButton } from "../Styling/Buttons"
import {
  inputField,
  inputFieldBlue,
  inputFieldError,
  inputFieldErrorAmount,
} from "../Styling/InputField"
import { DonorAgreementProps } from "../Utils/DonorAgreementTypes"

export const MyDonorAgreementAmount = ({
  props,
  setProps,
}: {
  props: DonorAgreementProps
  setProps: Dispatch<SetStateAction<DonorAgreementProps>>
}) => {
  const [inputAmount, setInputAmount] = useState(0)
  const [errorPayment, setErrorPayment] = useState(false)
  const [errorAgreement, setErrorAgreement] = useState(false)

  const [button1Price, setButtonButton1Price] = useState("")
  const [button2Price, setButtonButton2Price] = useState("")
  const [price1, setPrice1] = useState(0)
  const [price2, setPrice2] = useState(0)

  useEffect(() => {
    if (props.frequency === "Årlig") {
      setButtonButton1Price("350,-")
      setButtonButton2Price("1000,-")
      setPrice1(350)
      setPrice2(1000)
    } else if (props.frequency === "Halvårlig") {
      setButtonButton1Price("100,-")
      setButtonButton2Price("350,-")
      setPrice1(100)
      setPrice2(350)
    } else if (props.frequency === "Månedlig") {
      setButtonButton1Price("75,-")
      setButtonButton2Price("150,-")
      setPrice1(75)
      setPrice2(150)
    } else if (props.frequency === "Engangsbeløp") {
      setButtonButton1Price("250,-")
      setButtonButton2Price("500,-")
      setPrice1(250)
      setPrice2(500)
    } else {
      setButtonButton1Price("")
      setButtonButton2Price("")
      setPrice1(0)
      setPrice2(0)
    }
  }, [props, setProps])

  return (
    <div>
      {props.frequency.length > 0 ? (
        <div>
          <div className="mb-2">
            <label className="font-sans text-blue">Beløp</label>
          </div>
          <div className="flex flex-wrap space-x-4 mb-2">
            <button
              className={
                props.chipButtonClass === "1"
                  ? " bg-blue border-2 border-blue p-1 px-3 pt-1  font-sans text-white "
                  : chipButton + down
              }
              onClick={() => {
                setProps({
                  ...props,
                  amount: price1,
                  customAmount: false,
                  chipButtonClass: "1",
                })
              }}
            >
              {button1Price}
            </button>

            <button
              className={
                props.chipButtonClass === "2"
                  ? " bg-blue border-2 border-blue p-1 px-3 pt-1 font-sans text-white "
                  : chipButton + down
              }
              onClick={() => {
                setProps({
                  ...props,
                  amount: price2,
                  customAmount: false,
                  chipButtonClass: "2",
                })
              }}
            >
              {button2Price}
            </button>
            <button
              className={
                props.chipButtonClass === "custom"
                  ? " bg-blue border-2 border-blue p-1 px-3 pt-1  mr-4  font-sans text-white "
                  : chipButton + down
              }
              onClick={() => {
                setProps({
                  ...props,
                  customAmount: true,
                  amount: 0,
                  chipButtonClass: "custom",
                })
              }}
            >
              Valgfritt
            </button>
          </div>{" "}
          {props.customAmount ? (
            <div>
              <input
                type="number"
                className={
                  props.amount < 25 ? inputFieldErrorAmount : inputFieldBlue
                }
                placeholder="Skriv et ønsket beløp"
                onChange={e => {
                  setInputAmount(e.target.valueAsNumber)
                  setProps({ ...props, amount: e.target.valueAsNumber })
                }}
              ></input>
              {props.amount < 25 ? (
                <div className="mt-2">
                  <p className="font-sans text-orange-dark">
                    Beløpet må være minst 25,-
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
